import { Controller } from '@hotwired/stimulus';
import { squareMeterRound } from '../utilities/square_meter_calculations';

export default class extends Controller {
  static values = {
        minAmount: Number
  }

  static targets = [
    'commission',
    'recurringMessage',
    'squareMeterPrice',
    'squareMeters',
    'totalPrice',
    'totalPriceOverview',
    'recurringEnabled',
    'recurringFrequency',
    'frequencyText'
  ];

  connect() {
    this.calculate();
  }

  calculate() {
    var toggle = document.getElementById('confirmToggle');

    if (
      this.totalPriceTarget.value >= this.minAmountValue
    ) {
      toggle.removeAttribute('disabled');
    } else {
      toggle.setAttribute('disabled', 'disabled');
    }

    if (this.recurringEnabledTarget.checked) {
      this.recurringMessageTarget.style.display = 'inline';
      this.frequencyTextTarget.innerHTML = this.recurringFrequencyTarget.options[this.recurringFrequencyTarget.selectedIndex].text.toLowerCase();
    } else {
      this.recurringMessageTarget.style.display = 'none';
      this.frequencyTextTarget.innerHTML = '';
    }

    var squareMeters = squareMeterRound((this.totalPriceTarget.value * (1 - this.getCommission())) / this.getPrice());

    this.squareMetersTargets.forEach(el => {
      el.innerHTML = squareMeters;
    });

    this.totalPriceOverviewTargets.forEach(el => {
      el.innerHTML = this.totalPriceTarget.value;
    });
  }

  getPrice() {
    return this.squareMeterPriceTarget.getAttribute('data-square-meter-price');
  }

  getCommission() {
    return this.commissionTarget.getAttribute('data-commission');
  }
}
